<template>
	<div class="flex" :class="{ 'calls-show': hasSubRoute }">
		<div ref="inbox" class="inbox">
			<div class="inbox-header pb-0">
				<div class="inbox-title">
					<span class="inbox-title-label">
						{{ partner.name || 'Provider' }}
					</span>
					<span
						class="inbox-title-badge"
						data-tooltip="Total Providers"
						data-position="left"
					>
						{{ providers.length }}
					</span>
				</div>
			</div>
			<div class="inbox-group border-t-0">
				<div class="inbox-group-title">
					Providers
				</div>
				<provider-card
					v-for="provider in providers"
					:key="provider.id"
					:provider="provider"
				/>
			</div>
		</div>
		<router-view />
	</div>
</template>

<script>
import { orderBy } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import ProviderCard from '@/components/ProviderCard'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		ProviderCard,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if the current page is the providers show route.
		 *
		 * @return {Boolean}
		 */
		hasSubRoute() {
			return this.$route.name === 'app.providers.edit'
		},

		/**
		 * Get all providers sorted by last name.
		 *
		 * @return {Array}
		 */
		providers() {
			let providers = this.getProvidersByPartner(this.partner.id)

			providers = [...providers, ...this.getExternalProviders]

			providers = providers.map(provider => {
				return {
					...provider,
					last_name: provider.full_name.split(' ')[1],
				}
			})

			return orderBy(providers, ['last_name'], ['asc'])
		},

		...mapGetters({
			partner: 'partner',
			getExternalProviders: 'providers/external',
			getProvidersByPartner: 'providers/getInternalByPartner',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: mapActions('providers', ['get']),

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Providers',
}
</script>
